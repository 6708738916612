import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import {
  return_logo_from_type,
} from '../helpers/station-helper';
import Station from '../models/station';
import AuthenticationService from '../services/authentication-service';
import Company from '../models/company';
import SiteService from '../services/site-service';
import { io } from 'socket.io-client';
import { WebsocketEmitRequest } from '../models/websocket';
import { authHeader } from '../helpers/auth-headers';
import Transmitter from '../models/transmitter';
import {Item, createItem, getItemFromName, updateItemFromNameWithValueAndSetter} from '../helpers/items-helper';
import {debugFunctionIdValue} from '../helpers/tools';

type Props = {
  station: Station,
  fullscreen: boolean
};

const StationCardGestion: FunctionComponent<Props> = ({ station, fullscreen }) => {
  const displayName = "StationCardGestion:";
  const enableDebug = false;
  const enableDebugWebsocket = false;

  const history = useNavigate();
  const [readyToValidate, setReadyToValidate] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [freeTransmitters, setFreeTransmitters] = useState<Transmitter[]>([]);
  const [websocketEmit, setWebsocketEmit] = useState<WebsocketEmitRequest | undefined>(undefined);
  const [items, setItems] = useState<Item[]>([]);

  const current_user_is_god = AuthenticationService.getUserIsGod();

  useEffect(() => {
    enableDebug && console.log(displayName, "useEffect start");
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      enableDebug && console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("answer", (answer: any) => {
      enableDebug && console.log(displayName, "Answer:", answer);
    });
    socket.on("companies_desc", (values_desc_str: string) => {
      const values = JSON.parse(values_desc_str);
      enableDebug && values && console.log(displayName, "Companies:", values);
      (values) && (values.length > 0) && setCompanies(values);
      const the_request: any = {
        type: "transmitters_desc",
        number: 1,
        liste: [6],
        filter: ["no_transmitters_desc", "mac", "id"],
        from: "company_id",
        token: authHeader()
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    });
    socket.on("transmitters_desc", (values_desc_str: string) => {
      const values = JSON.parse(values_desc_str);
      enableDebug && values && console.log(displayName, "transmitters:", values);
      (values) && (values.length > 0) && setFreeTransmitters(values);
    });
    socket.on("done", (values_desc_str: string) => {
      history(window.location.pathname);
      window.location.reload();
    });
    if (websocketEmit) {
      if (enableDebugWebsocket) console.log(displayName, "----------> websocket emit :", websocketEmit);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    enableDebug && console.log(displayName, "useEffect end");
    return function cleanup() {
      enableDebug && console.log(displayName, "useEffect.cleanup")
      socket.disconnect();
    };
  }, [websocketEmit]);
  useEffect(() => {
    enableDebug && console.log(displayName, "item(station_move_to_company-"+station.id+").value:", getItemFromName(items, "station_move_to_company-" + station.id).value);
    enableDebug && console.log(displayName, "station.company_id:", station.company_id);
    if((getItemFromName(items, "station_move_to_company-" + station.id).value !== "0") && (getItemFromName(items, "station_move_to_company-" + station.id).value !== station.company_id.toString())){
      setReadyToValidate(true);
    }else if(get_first_transmitter_to_move(station, items)!==undefined){
      setReadyToValidate(true);
    }else{
      setReadyToValidate(false);
    }
  }, [items]);

  useEffect(() => {
    const the_request: any = {
      type: "companies_desc",
      number: 0,
      liste: [],
      from: "token",
      token: authHeader()
    }
    setWebsocketEmit({ channel: "request", request: the_request });
  }, []);

  useEffect(() => {
    enableDebug && console.log(displayName,"freeTransmitters=>",freeTransmitters);
    let newItemArray: Array<Item> = [];
    newItemArray = updateItemFromNameWithValueAndSetter(newItemArray, "station_move_to_company-" + station.id, "0", setItems);
    station.transmitters.forEach(one=>{
      newItemArray = updateItemFromNameWithValueAndSetter(newItemArray, "transmitter_to_move-" + one.mac, "0", setItems);
    })
  }, [freeTransmitters]);

  useEffect(() => {
    enableDebug && console.log(displayName, "items:", items)
  }, [items]);

  function get_first_transmitter_to_move(station: Station, items: Item[]):Transmitter | undefined{
    return station.transmitters.find(one => getItemFromName(items, "transmitter_to_move-" + one.mac).value !== "0");
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (getItemFromName(items, "station_move_to_company-" + station.id).value !== "0") {
      const the_command = { "command": "move_from_station_id_to_company_id", "from_station_id": station.id, "to_company_id": getItemFromName(items, "station_move_to_company-" + station.id).value }
      const the_request: any = {
        type: "commands",
        number: 1,
        liste: [JSON.stringify(the_command)],
        from: "token",
        token: authHeader()
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    }else{
      const the_transmitter_to_move = get_first_transmitter_to_move(station, items);
      if(the_transmitter_to_move){
        const the_command = { "command": "switch_transmitter_mac", "old_mac": the_transmitter_to_move.mac, "new_mac": getItemFromName(items, "transmitter_to_move-" + the_transmitter_to_move.mac).value }
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify(the_command)],
          from: "token",
          token: authHeader()
        }
        setWebsocketEmit({ channel: "request", request: the_request });
      }
    }
  }

  const handleGenericSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleGenericSelectChange", e.target.id, e.target.value);
    updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, setItems);
  }

  function gestion_display(station: Station) {
    return (
      <div className="panel-station-installer-column">
        <hr></hr>
        <div key={"sensor-station-god-" + station.id} className="panel-station-setup-sensor-content-part-line panel-station-installer-line-height">
          <div className="panel-station-installer-column panel-station-installer-line-height">
            Déplacer la station version une autre société :
          </div>
          <div className="panel-station-installer-column">
            {(companies.length > 0) && (
              <select data-tip={"Déplacer vers cette société"} className="panel-station-installer-line-height panel-station-installer-value" key={"move_to_company"} id={"station_move_to_company-" + station.id} value={getItemFromName(items, "station_move_to_company-" + station.id).value} onChange={e => handleGenericSelectChange(e)}>
                <option value="0">SélectionnerUneSociété</option>
                {companies?.map(company => { return (<option value={company.id} key={company.id}>{company.name}</option>) })}
              </select>
            )}
          </div>
        </div>
        <hr></hr>
        <hr></hr>
        <div key={"transmitter-station-god-" + station.id} className="panel-station-setup-sensor-content-part-line panel-station-installer-line-height">
          Remplacer un des transmetteurs de la station:
        </div>
        {(station.transmitters.length > 0) && station.transmitters.map(one_t => {
          return (
            <div key={"transmitter-station-god-" + one_t.id} className="panel-station-setup-sensor-content-part-line panel-station-installer-line-height">
              <div className="panel-station-installer-column panel-station-installer-line-height">
              {one_t.mac}&#x21C4;
              </div>
              <div className="panel-station-installer-column">
                {(freeTransmitters.length > 0) && (
                  <select data-tip={"Remplacer transmetteur"} className="panel-station-installer-line-height panel-station-installer-value" key={"replace_transmitter"} id={"transmitter_to_move-" + one_t.mac} value={getItemFromName(items, "transmitter_to_move-" + one_t.mac).value} onChange={e => handleGenericSelectChange(e)}>
                    <option value="0">SélectionnerUneMAC</option>
                    {freeTransmitters?.map(transmitter => { return (<option value={transmitter.mac} key={transmitter.mac}>{transmitter.mac}</option>) })}
                  </select>
                )}
              </div>
            </div>
          )
        })
        }
          <hr></hr>
        </div>
    )
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        <div className="panel-installer-station-main-bottom">
          {(current_user_is_god) && gestion_display(station)}
        </div>
        {/* Do not disable following else even the admin can't validate when previously disabled */}
        <button type="submit" className="btn-station-card align-self-center" disabled={!readyToValidate}>
          {(!readyToValidate) ? return_logo_from_type("loader", "icon-sensor-30") : "Valider"}
        </button>
      </div>
      <ReactTooltip type="light" />
    </form>
  );
}
export default StationCardGestion;
