import { FunctionComponent, useState, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  return_logo_from_type,
  is_there_sensor_in_array,
  get_all_analog_sensors_from_station,
  get_all_water_level_sensors_from_station,
  return_battery_logo_from_level
} from '../helpers/station-helper';
import AnalogSensor from '../models/analog-sensor';
import Station from '../models/station';
import AuthenticationService from '../services/authentication-service';
import StationService from '../services/station-service';
import SensorService from '../services/sensor-service';
import { get_date_as_string, replace_specific_char } from '../helpers/tools';

type Props = {
  station: Station,
  fullscreen: boolean
};

const StationCardDownload: FunctionComponent<Props> = ({station, fullscreen}) => {
  const analog_sensors_type_allowed_by_all_users = ["temperature"]

  const [uploading, setUploading] = useState<string>("");
  const [clickedFilename, setClickedFilename] = useState<string>("");
  const [readyToDownload, setReadyToDownload] = useState<boolean>(false);
  const [transactionData, setTransactionData] = useState([])
  const [period, setPeriod] = useState("all");
  const csvLinkRef = useRef<any | null>(null);
  const [downloadables, setDownloadables] = useState<string[]>([]);
  const current_user_is_god = AuthenticationService.getUserIsGod();
  const current_user_can_modify = AuthenticationService.getUserCanAccessModify(station, "download") || current_user_is_god;

  let water_level_sensors = get_all_water_level_sensors_from_station(station);
  let analog_sensors = get_all_analog_sensors_from_station(station);

  useEffect(() => {
    StationService.getDownloadableForStation(station.id).then(result=>{
      setDownloadables(result);
    });
  },[station.id])

  useEffect(() => {
    if(transactionData.length > 0 && readyToDownload){
      if(csvLinkRef?.current){
        csvLinkRef.current.link.click();
      }
      setReadyToDownload(false);
    }
    if(readyToDownload){
      setUploading("");
    }
  },[readyToDownload, transactionData.length])

  const getAnalogSensorDownloadData = (sensorId:number, period:string) => {
    SensorService.getDownloadAnalogSensor(sensorId, period).then(r => {
      r && setTransactionData(r);
      setReadyToDownload(true);
    }).catch(error => console.log(error));
  }

  function displayAnalogSensorDownloadButton(sensor: AnalogSensor) {
    //TODO ?! api side ?!
    if(sensor.name==="data"){
      return;
    }
    return(
      <div key={"download-analog-"+sensor.id} className="panel-download-sensor-line">
        <div className="panel-download-sensor-element-name">{(sensor.name)?(sensor.name):(sensor.type)}</div>
        <div className="panel-download-sensor-element-logo">{return_logo_from_type(sensor.type, "logo-sensor-half-size-png")}</div>
        <div className="panel-download-sensor-element">
          <button disabled={(!current_user_can_modify)} type="submit" className="btn-station-card align-self-center" onClick={()=>{
            setUploading("download-analog-"+sensor.id);
            if(sensor.name){
              setClickedFilename(get_date_as_string()+"-"+replace_specific_char(station.name)+"-"+period+"+"+sensor.type+'-'+replace_specific_char(sensor.name)+'.csv');
            }else{
              setClickedFilename(get_date_as_string()+"-"+replace_specific_char(station.name)+"-"+period+"+"+sensor.type+'-'+sensor.id+'.csv');
            }
            getAnalogSensorDownloadData(sensor.id, period);
            }}>
          {(uploading==="download-analog-"+sensor.id)?return_logo_from_type("loader","icon-sensor-30"):"Télécharger"}
          </button>
        </div>
      </div>
    )
  }

  const getWaterLevelSensorDownloadData = (stationId:number, period:string) => {
    SensorService.getDownloadWaterlevelSensorPerStation(stationId, period).then(r => {
      setTransactionData(r);
      setReadyToDownload(true);
    }).catch(error => console.log(error));
  }

  const getSensorsBrutValuesDownloadData = (stationId:number, period:string) => {
    SensorService.getDownloadSensorsBrutValuesPerStation(stationId, period).then(r => {
      setTransactionData(r);
      setReadyToDownload(true);
    }).catch(error => console.log(error));
  }

  const getTransmitterssBrutValuesDownloadData = (stationId:number, period:string) => {
    SensorService.getDownloadTransmittersBrutValuesPerStation(stationId, period).then(r => {
      setTransactionData(r);
      setReadyToDownload(true);
    }).catch(error => console.log(error));
  }

  const getbatteriesDownloadData = (stationId:number, period:string) => {
    SensorService.getDownloadBatteriesPerStation(stationId, period).then(r => {
      setTransactionData(r);
      setReadyToDownload(true);
    }).catch(error => console.log(error));
  }

  function displayAllWaterlevelSensorDownloadButton(stationId: number) {
    return(
      <div key={"download-station-waterlevel-"+stationId} className="panel-download-sensor-line">
        <div className="panel-download-sensor-element-name">Niveaux d'eau</div>
        <div className="panel-download-sensor-element-logo">{return_logo_from_type("water_level", "logo-sensor-half-size-png")}</div>
        <div className="panel-download-sensor-element">
          <button disabled={(!current_user_can_modify)} type="submit" className="btn-station-card align-self-center" onClick={()=>{
            setUploading("download-station-waterlevel-"+stationId);
            setClickedFilename(get_date_as_string()+"-"+replace_specific_char(station.name)+"-"+period+'+waterlevel.csv');
            getWaterLevelSensorDownloadData(stationId, period)
            }}>
            {(uploading==="download-station-waterlevel-"+stationId)?return_logo_from_type("loader","icon-sensor-30"):"Télécharger"}
          </button>
        </div>
      </div>
    )
  }

  function displayBrutSensorsValuesDownloadButton(stationId: number) {
    return(
      <div key={"download-brut-sensors-station-all-"+stationId} className="panel-download-sensor-line">
        <div className="panel-download-sensor-element-name">Capteurs(GOD)</div>
        <div className="panel-download-sensor-element-logo">{return_logo_from_type("all_analog", "logo-sensor-half-size-png")}</div>
        <div className="panel-download-sensor-element">
          <button disabled={(!current_user_can_modify)} type="submit" className="btn-station-card align-self-center" onClick={()=>{
            setUploading("download-brut-sensors-station-all-"+stationId);
            setClickedFilename(get_date_as_string()+"-"+replace_specific_char(station.name)+"-"+period+'+brut_sensors.csv');
            getSensorsBrutValuesDownloadData(stationId, period);
            }}>
          {(uploading==="download-brut-sensors-station-all-"+stationId)?return_logo_from_type("loader","icon-sensor-30"):"Télécharger"}
          </button>
        </div>
      </div>
    )
  }

  function displayBrutTransmittersValuesDownloadButton(stationId: number) {
    return(
      <div key={"download-brut-transmitters-station-all-"+stationId} className="panel-download-sensor-line">
        <div className="panel-download-sensor-element-name">Transmetteurs(GOD)</div>
        <div className="panel-download-sensor-element-logo">{return_logo_from_type("transmitter", "logo-sensor-half-size-png")}</div>
        <div className="panel-download-sensor-element">
          <button disabled={(!current_user_can_modify)} type="submit" className="btn-station-card align-self-center" onClick={()=>{
            setUploading("download-brut-transmitters-station-all-"+stationId);
            setClickedFilename(get_date_as_string()+"-"+replace_specific_char(station.name)+"-"+period+'+brut_transmitters.csv');
            getTransmitterssBrutValuesDownloadData(stationId, period);
            }}>
          {(uploading==="download-brut-transmitters-station-all-"+stationId)?return_logo_from_type("loader","icon-sensor-30"):"Télécharger"}
          </button>
        </div>
      </div>
    )
  }

  function displayBatteriesDownloadButton(stationId: number) {
    return(
      <div key={"download-batteries-station-all-"+stationId} className="panel-download-sensor-line">
        <div className="panel-download-sensor-element-name">Batteries(GOD)</div>
        <div className="panel-download-sensor-element-logo">{return_battery_logo_from_level(0, "logo-sensor-half-size-png")}</div>
        <div className="panel-download-sensor-element">
          <button disabled={(!current_user_can_modify)} type="submit" className="btn-station-card align-self-center" onClick={()=>{
            setUploading("download-batteries-station-all-"+stationId);
            setClickedFilename(get_date_as_string()+"-"+replace_specific_char(station.name)+"-"+period+'+batteries_transmitters.csv');
            getbatteriesDownloadData(stationId, period)
            }}>
          {(uploading==="download-batteries-station-all-"+stationId)?return_logo_from_type("loader","icon-sensor-30"):"Télécharger"}
          </button>
        </div>
      </div>
    )
  }

  function isBtnSelected(current: string | undefined, trigger: string){
    if ( (current) && (current === trigger) ){
      return "btn-selected"
    }
    return ""
  }

  const getFileDownloadData = (stationId:number, filename:string) => {
    StationService.downloadForStation(stationId, filename).then(blob => {
      if(blob){
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }).catch(error => console.log(error));
  }

  function displayOneDownloadableFile(stationId: number,one: string) {
    return(
      <div key={"download-one-station-all-"+stationId+"-"+one} className="panel-download-sensor-line">
        <div className="panel-download-sensor-element-name">{one}</div>
        <div className="panel-download-sensor-element-logo">{return_logo_from_type("file", "logo-sensor-half-size-png")}</div>
        <div className="panel-download-sensor-element">
          <button disabled={(!current_user_can_modify)} type="submit" className="btn-station-card align-self-center" onClick={()=>{
            setUploading("download-one-station-all-"+stationId+"-"+one);
            getFileDownloadData(stationId, one);
            }}>
          {(uploading==="download-one-station-all-"+stationId+"-"+one)?return_logo_from_type("loader","icon-sensor-30"):"Télécharger"}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="panel-download-flex">
      <div className="panel-chart-flex-sensor-time">
        {(!current_user_can_modify) ? (<div className="text-warning-font">Vous n'avez pas accès à cette section</div>):(<div className="text-warning-font">&nbsp;</div>)}
      </div>
      <div className="panel-chart-flex-sensor-time">
        <button disabled={(!current_user_can_modify)} className={"btn-sensor-time "+isBtnSelected(period, "week")} onClick={()=> setPeriod("week")}>Semaine</button>
        <button disabled={(!current_user_can_modify)} className={"btn-sensor-time "+isBtnSelected(period, "month")} onClick={()=> setPeriod("month")}>Mois</button>
        <button disabled={(!current_user_can_modify)} className={"btn-sensor-time "+isBtnSelected(period, "year")} onClick={()=> setPeriod("year")}>Année</button>
        <button disabled={(!current_user_can_modify)} className={"btn-sensor-time "+isBtnSelected(period, "all")} onClick={()=> setPeriod("all")}>Tous</button>
      </div>
      {(analog_sensors) && is_there_sensor_in_array(analog_sensors) && analog_sensors.map(one => {
        if((current_user_is_god)||(analog_sensors_type_allowed_by_all_users.indexOf(one.type)>-1)){return displayAnalogSensorDownloadButton(one);}else return undefined
      })}
      {(water_level_sensors) && is_there_sensor_in_array(water_level_sensors) && displayAllWaterlevelSensorDownloadButton(station.id)}
      {(current_user_is_god) && (displayBatteriesDownloadButton(station.id))}
      {(current_user_is_god) && (displayBrutSensorsValuesDownloadButton(station.id))}
      {(current_user_is_god) && (displayBrutTransmittersValuesDownloadButton(station.id))}
      {(downloadables.length>0) && downloadables.map(one=>displayOneDownloadableFile(station.id, one))}
      <CSVLink
        className='hidden'
        target='_blank'
        data={transactionData}
        filename={clickedFilename}
        ref={csvLinkRef}
        separator={";"}
      />
    </div>
  );
}

export default StationCardDownload;
